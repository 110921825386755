<template>
  <!-- eslint-disable max-len -->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g transform="translate(3 1)">
      <rect
        class="a"
        width="24"
        height="24"
        transform="translate(-3 -1)"
      />
      <path
        class="b"
        d="M123.825,48.9V63.392a2.529,2.529,0,0,1-1.116,1.967l-6.768,3.959a2.512,2.512,0,0,1-2.232,0l-6.768-3.959a2.529,2.529,0,0,1-1.116-1.967V48.9a1.312,1.312,0,0,1,1.286-1.32h15.4A1.332,1.332,0,0,1,123.825,48.9Zm-6.841,2.366h-1.722V61.948h1.722Zm2.6,1.768h-1.722v8.89h1.722Zm-5.191,5.329h-1.722v3.561h1.722Zm-2.6-2.664H110.07v6.225h1.722Z"
        transform="translate(-105.825 -47.58)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconLogo',
};
</script>

<style>
  .a {
    fill: currentColor;
    opacity: 0;
  }
  .b {
    fill: currentColor;
  }
</style>
